import useGetUsersByTenantID from "@/api/core/hooks/useGetUsersByTenantID";
import useAvailableDimensionsByDataSource from "@/hooks/useAvailableDimensionsByDataSource";
import useGatekeeper from "@/hooks/useGatekeeper";
import { AlertType, postAlert } from "@/utils/alerts";
import { useTheme } from "@emotion/react";
import { faLock, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import {
  DashboardScope,
  DashboardType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
  WidgetType,
} from "@ternary/api-lib/constants/enums";
import systemUser from "@ternary/api-lib/constants/system";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { startOfDay, startOfHour } from "date-fns";
import { keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import useGetDimensionValues from "../../../api/analytics/useGetDimensionValues";
import { QueryFilter } from "../../../api/core/types";
import NameInputModal from "../../../components/NameInputModal";
import SideDrawer from "../../../components/SideDrawer";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { DateHelper } from "../../../lib/dates";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import TextInput from "../../../ui-lib/components/TextInput";
import getMergeState from "../../../utils/getMergeState";
import useGetDimensionPreferencesByTenantID from "../../admin/hooks/useGetDimensionPreferencesByTenantID";
import DashboardListModal from "../../reporting-engine/components/DashboardListModal";
import useCreateDashboard from "../../reporting-engine/hooks/useCreateDashboard";
import useGetDashboardsByTenantID from "../../reporting-engine/hooks/useGetDashboardsByTenantID";
import useUpdateDashboard from "../../reporting-engine/hooks/useUpdateDashboard";
import { formatWidgetSpec } from "../../reporting-engine/utils";
import { FormType } from "../constants";
import copyText from "../copyText";
import useCreateBudget from "../hooks/useCreateBudget";
import useDeleteBudget from "../hooks/useDeleteBudget";
import useGetBudgetsByTenantID from "../hooks/useGetBudgetsByTenantID";
import useGetBudgetSpendByGranularity from "../hooks/useGetBudgetSpendByGranularity";
import useUpdateBudget from "../hooks/useUpdateBudget";
import { BudgetScope } from "../types";
import {
  getAmountFromBudget,
  getSortedMonthsFromPeriodVersions,
  getYearsFromBudget,
} from "../utils";
import BudgetForm from "./BudgetForm";
import BudgetsChartsSection from "./BudgetsChartsSection";
import BudgetsTable from "./BudgetsTable";
import BudgetTypeModal from "./BudgetTypeModal";

type Interaction = DashboardListModal.Interaction | BudgetTypeModal.Interaction;

const MODAL_DASHBOARD_LIST = "DASHBOARD_LIST";
const MODAL_SAVE_TO_DASHBOARD_CONFIRMATION = "SAVE_TO_DASHBOARD_CONFIRMATION";
const MODAL_CREATE_DASHBOARD = "CREATE_DASHBOARD";
const MODAL_DELETE_CONFIRMATION = "DELETE_CONFIRMATION";
const MODAL_SELECT_BUDGET_TYPE = "SELECT_BUDGET_TYPE";

interface State {
  currentViewBudgetDimensions: string[];
  formType: FormType | null;
  dashboardWidgetType: WidgetType;
  modalKey?: string;
  searchText: string;
  selectedBudgetID: string | null;
  selectedDashboardID: string | null;
  selectedYear: number;
}

const defaultDashboards = [];

export default function BudgetsManagementContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const now = new DateHelper();

  //
  // State
  //

  const [currentViewBudgetID, setCurrentViewBudgetID] = useQueryParam(
    "budget_id",
    StringParam
  );

  const [state, setState] = useState<State>({
    currentViewBudgetDimensions: [],
    formType: null,
    modalKey: undefined,
    searchText: "",
    selectedBudgetID: null,
    selectedDashboardID: null,
    selectedYear: now.date.getFullYear(),
    dashboardWidgetType: WidgetType.BUDGET_CURRENT_MONTH,
  });

  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const availableDimensions = useAvailableDimensionsByDataSource(
    DataSource.BILLING
  );

  const {
    data: _budgets,
    isLoading: isLoadingBudgets,
    refetch: refetchBudgets,
  } = useGetBudgetsByTenantID(authenticatedUser.tenant.fsDocID);

  const currentViewBudget = _budgets?.find(
    (budget) => budget.id === currentViewBudgetID
  );

  const dataSourceDimenionsParam = [
    {
      dataSource: DataSource.BILLING,
      dimensions: state.currentViewBudgetDimensions,
    },
  ];

  const { data: dimensionValuesMap = {}, isLoading: isLoadingDimensionValues } =
    useGetDimensionValues(
      {
        dateRange: getCubeDateRangeFromDurationType(
          DurationType.LAST_NINETY_DAYS
        ),
        dataSourceDimensions: dataSourceDimenionsParam,
      },
      { enabled: state.currentViewBudgetDimensions.length > 0 }
    );

  const { data: dailySpend = [], isLoading: isLoadingDailyBudgetSpend } =
    useGetBudgetSpendByGranularity(
      {
        budget: currentViewBudget,
        dateRange: [
          startOfDay(now.nMonthsAgoFirstOfMonth(6)),
          startOfHour(now.date),
        ],
        granularity: TimeGranularity.DAY,
      },
      { enabled: !!currentViewBudget }
    );

  const firstPeriodVersion =
    currentViewBudget?.periodVersions &&
    currentViewBudget.periodVersions.length > 0
      ? new Date(getSortedMonthsFromPeriodVersions(currentViewBudget, true)[0])
      : startOfHour(now.date);

  const firstOfSelectedYear = startOfHour(new Date(state.selectedYear, 0));

  const { data: monthlySpend = [], isLoading: isLoadingMonthlyBudgetSpend } =
    useGetBudgetSpendByGranularity(
      {
        budget: currentViewBudget,
        dateRange: [firstOfSelectedYear, firstPeriodVersion],
        granularity: TimeGranularity.MONTH,
      },
      { enabled: !!currentViewBudget }
    );

  const { data: users = [] } = useGetUsersByTenantID(
    authenticatedUser.tenant.id
  );

  const { data: dimensionPreferences = [] } =
    useGetDimensionPreferencesByTenantID(authenticatedUser.tenant.id);

  const usersKeyedByID = keyBy([...users, systemUser], "id");

  const {
    data: _dashboards = defaultDashboards,
    isLoading: isLoadingDashboards,
    refetch: refetchDashboards,
  } = useGetDashboardsByTenantID(authenticatedUser.tenant.id);

  const dashboards = _dashboards.map((dashboard) => {
    const createdByUser = usersKeyedByID[dashboard.createdByID];
    const updatedByUser = usersKeyedByID[dashboard.updatedByID ?? ""];

    return {
      ...dashboard,
      createdByEmail: createdByUser ? createdByUser.email : null,
      updatedByEmail: updatedByUser ? updatedByUser.email : null,
    };
  });

  //
  // Mutations
  //

  const { isPending: isCreatingBudget, mutate: createBudget } = useCreateBudget(
    {
      onError: () => {
        postAlert({
          message: copyText.errorCreatingBudgetMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        mergeState({
          formType: null,
          selectedBudgetID: null,
        });

        refetchBudgets();

        postAlert({
          message: copyText.successCreatingBudgetMessage,
          type: AlertType.SUCCESS,
        });
      },
    }
  );

  const { isPending: isDeletingBudget, mutate: deleteBudget } = useDeleteBudget(
    {
      onError: () => {
        postAlert({
          message: copyText.errorDeletingBudgetMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        mergeState({ selectedBudgetID: null, modalKey: undefined });

        refetchBudgets();

        postAlert({
          message: copyText.successDeletingBudgetMessage,
          type: AlertType.SUCCESS,
        });
      },
    }
  );

  const { isPending: isUpdatingBudget, mutate: updateBudget } = useUpdateBudget(
    {
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingBudgetMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        mergeState({
          formType: null,
          selectedBudgetID: null,
        });

        refetchBudgets();

        postAlert({
          message: copyText.successUpdatingBudgetMessage,
          type: AlertType.SUCCESS,
        });
      },
    }
  );

  const { isPending: isCreatingDashboard, mutate: createDashboard } =
    useCreateDashboard({
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorCreatingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (dashboardID) => {
        refetchDashboards();

        mergeState({
          modalKey: MODAL_SAVE_TO_DASHBOARD_CONFIRMATION,
          selectedDashboardID: dashboardID,
        });

        postAlert({
          message: copyText.successCreatingDashboardMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { mutate: updateDashboard } = useUpdateDashboard({
    onError: () => {
      mergeState({ modalKey: undefined });
      postAlert({
        message: copyText.errorUpdatingDashboardMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (dashboardID) => {
      queryClient.resetQueries({
        queryKey: ["dashboards", dashboardID],
      });

      refetchDashboards();

      mergeState({ modalKey: undefined });

      mergeState({ selectedDashboardID: undefined });

      postAlert({
        message: copyText.successUpdatingDashboardMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Side Effects
  //

  useEffect(() => {
    if (!_budgets) return;

    const largestBudget = _budgets
      .sort(
        (a, b) => (getAmountFromBudget(b) || 0) - (getAmountFromBudget(a) || 0)
      )
      .find((budget) => budget.status !== null);

    if (!largestBudget) return;

    setCurrentViewBudgetID((selectedBudgetID) =>
      selectedBudgetID ? selectedBudgetID : largestBudget.id
    );
  }, [_budgets === undefined]);

  //
  // Interaction Handlers
  //

  function handleChangeCurrentViewBudget(budgetID: string) {
    const currentViewBudget = _budgets?.find(
      (budget) => budget.id === budgetID
    );

    if (!currentViewBudget) return;

    const selectedYear =
      getYearsFromBudget(currentViewBudget)[0] || now.date.getFullYear();

    const currentViewBudgetDimensions = currentViewBudget.scopes.map(
      (scope) => scope.key
    );

    mergeState({
      currentViewBudgetDimensions,
      selectedYear,
    });

    setCurrentViewBudgetID(budgetID);
  }

  function handleCreateDashboard(name: string) {
    createDashboard({
      tenantID: authenticatedUser.tenant.id,
      name,
      widgetSpecs: [],
      scope: DashboardScope.SHARED,
      type: DashboardType.DEFAULT,
    });
  }

  function handleUpdateDashboard() {
    if (!selectedBudget || !state.selectedDashboardID || !selectedDashboard) {
      return;
    }

    const newBudgetSpec = formatWidgetSpec(
      selectedBudget.id,
      state.dashboardWidgetType,
      selectedDashboard?.widgetSpecs
    );

    const updatedWidgetSpecs = [
      ...selectedDashboard.widgetSpecs,
      newBudgetSpec,
    ].map((spec) => ({
      ...(spec.budgetID ? { budgetID: spec.budgetID } : {}),
      ...(spec.reportID ? { reportID: spec.reportID } : {}),
      ...(spec.savingsOpportunityFilterID
        ? { savingsOpportunityFilterID: spec.savingsOpportunityFilterID }
        : {}),
      height: spec.height,
      type: spec.type,
      width: spec.width,
      xCoordinate: spec.xCoordinate,
      yCoordinate: spec.yCoordinate,
    }));

    updateDashboard({
      dashboardID: state.selectedDashboardID,
      widgetSpecs: updatedWidgetSpecs,
    });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case BudgetTypeModal.INTERACTION_SELECT_CURRENT_MONTH: {
        mergeState({
          dashboardWidgetType: WidgetType.BUDGET_CURRENT_MONTH,
          modalKey: MODAL_SAVE_TO_DASHBOARD_CONFIRMATION,
        });
        return;
      }
      case BudgetTypeModal.INTERACTION_SELECT_DAILY_TRENDS: {
        mergeState({
          dashboardWidgetType: WidgetType.BUDGET_DAILY_TRENDS,
          modalKey: MODAL_SAVE_TO_DASHBOARD_CONFIRMATION,
        });
        return;
      }
      case DashboardListModal.INTERACTION_ROW_CLICKED: {
        mergeState({
          modalKey: MODAL_SELECT_BUDGET_TYPE,
          selectedDashboardID: interaction.dashboardID,
        });
        return;
      }
      case DashboardListModal.INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED: {
        mergeState({ modalKey: MODAL_CREATE_DASHBOARD });
        return;
      }
    }
  }

  //
  // Render
  //

  const selectedDashboard = dashboards.find(
    (dashboard) => dashboard.id === state.selectedDashboardID
  );

  if (!gatekeeper.canListBudgets) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const formTitle = (() => {
    switch (state.formType) {
      case FormType.COPY:
        return copyText.budgetFormTitleCopy;
      case FormType.CREATE:
        return copyText.budgetFormTitleCreate;
      case FormType.UPDATE:
        return copyText.budgetFormTitleEdit;
      default:
        return copyText.budgetFormTitleCreate;
    }
  })();

  let budgets = _budgets;

  if (_budgets && state.searchText.length > 0) {
    budgets = _budgets.filter((budget) => {
      return budget.name.toLowerCase().includes(state.searchText.toLowerCase());
    });
  }

  const selectedBudget = budgets?.find(
    (budget) => budget.id === state.selectedBudgetID
  );

  function renderModal() {
    switch (state.modalKey) {
      case MODAL_CREATE_DASHBOARD: {
        return (
          <NameInputModal
            isProcessing={isCreatingDashboard}
            title={copyText.modalTitleCreateDashboard}
            onClose={() =>
              mergeState({
                selectedDashboardID: undefined,
                modalKey: undefined,
              })
            }
            onSubmit={handleCreateDashboard}
          />
        );
      }
      case MODAL_DELETE_CONFIRMATION: {
        return (
          <ConfirmationModal
            isLoading={isDeletingBudget}
            message={copyText.deleteBudgetConfirmationMessage}
            title={copyText.deleteBudgetConfirmationTitle}
            variant="danger"
            onConfirm={() =>
              state.selectedBudgetID &&
              deleteBudget({ budgetID: state.selectedBudgetID })
            }
            onCancel={() => mergeState({ modalKey: undefined })}
          />
        );
      }
      case MODAL_DASHBOARD_LIST: {
        return (
          <DashboardListModal
            dashboards={dashboards}
            isLoading={isLoadingDashboards}
            title={copyText.modalTitleAddToDashboard}
            onClose={() =>
              mergeState({
                selectedDashboardID: undefined,
                modalKey: undefined,
              })
            }
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_SAVE_TO_DASHBOARD_CONFIRMATION: {
        return (
          <ConfirmationModal
            isLoading={false}
            message={copyText.modalTitleConfirmSaveExistingBudgetToDashboard
              .replace("%budget%", selectedBudget?.name ?? "budget")
              .replace("%dashboard%", selectedDashboard?.name ?? "dashboard")}
            title={copyText.modalTitleConfirm}
            onConfirm={handleUpdateDashboard}
            onCancel={() =>
              mergeState({
                selectedDashboardID: undefined,
                modalKey: undefined,
              })
            }
          />
        );
      }
      case MODAL_SELECT_BUDGET_TYPE: {
        return (
          <BudgetTypeModal
            onInteraction={handleInteraction}
            onClose={() =>
              mergeState({
                modalKey: undefined,
                selectedDashboardID: undefined,
              })
            }
          />
        );
      }
    }
  }
  return (
    <Box>
      {state.formType && (
        <SideDrawer
          isOpen
          title={formTitle}
          onClose={() => mergeState({ formType: null, selectedBudgetID: null })}
          renderContent={() => (
            <BudgetForm
              availableDimensions={availableDimensions}
              budget={selectedBudget}
              dimensionValues={dimensionValuesMap}
              formType={state.formType as FormType}
              dimensionPreferences={dimensionPreferences}
              isLoadingDimensionValues={isLoadingDimensionValues}
              isProcessing={
                isCreatingBudget || isDeletingBudget || isUpdatingBudget
              }
              users={users}
              onCancel={() =>
                mergeState({ formType: null, selectedBudgetID: null })
              }
              onCreate={(params) => createBudget(params)}
              onScopeChange={(scopeKeys) =>
                mergeState({ currentViewBudgetDimensions: scopeKeys })
              }
              onUpdate={(budgetID, params) =>
                updateBudget({
                  budgetID,
                  params,
                })
              }
              onUpdatePeriodVersions={(budgetID, updatedPeriodVersions) =>
                updateBudget({
                  budgetID,
                  params: { updatedPeriodVersions },
                })
              }
            />
          )}
        />
      )}
      <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
        {currentViewBudget?.name}
      </Text>
      <BudgetsChartsSection
        dailySpend={dailySpend}
        monthlySpend={monthlySpend}
        loading={isLoadingDailyBudgetSpend || isLoadingMonthlyBudgetSpend}
        selectedBudget={currentViewBudget}
        selectedYear={state.selectedYear}
        onSelectYear={(selectedYear: number) => mergeState({ selectedYear })}
      />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_lg}
      >
        <Text fontSize={theme.h3_fontSize}>{copyText.budgetTableTitle}</Text>
        <Flex alignItems="center">
          <Box width={300} marginRight={theme.space_lg}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={state.searchText}
              onChange={(event) =>
                mergeState({ searchText: event.target.value })
              }
            />
          </Box>
          <Button
            iconStart={<Icon icon={faPlus} />}
            locked={!gatekeeper.canCreateBudgets}
            primary
            onClick={() => mergeState({ formType: FormType.CREATE })}
          >
            {copyText.addBudgetButtonLabel}
          </Button>
        </Flex>
      </Flex>
      <BudgetsTable
        budgets={budgets ?? []}
        loading={isLoadingBudgets}
        selectedBudget={currentViewBudget}
        onAddToDashboard={(budgetID) =>
          mergeState({
            modalKey: MODAL_DASHBOARD_LIST,
            selectedBudgetID: budgetID,
          })
        }
        onSelectBudget={handleChangeCurrentViewBudget}
        onSelectCopy={(budgetID) =>
          mergeState({ formType: FormType.COPY, selectedBudgetID: budgetID })
        }
        onSelectDelete={(budgetID) =>
          mergeState({
            selectedBudgetID: budgetID,
            modalKey: MODAL_DELETE_CONFIRMATION,
          })
        }
        onSelectEdit={(budgetID) =>
          mergeState({ formType: FormType.UPDATE, selectedBudgetID: budgetID })
        }
      />
      {renderModal()}
    </Box>
  );
}

export function budgetSpendQueryFilter(
  scopes: BudgetScope[] | undefined
): QueryFilter[] {
  const filters: QueryFilter[] = [];
  if (scopes === undefined) return filters;

  scopes.forEach((scope) => {
    filters.push({
      name: scope.key,
      operator: Operator.EQUALS,
      values: scope.values,
    });
  });

  return filters;
}
